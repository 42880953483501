<template>
  <v-card>
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card-title class="float-left">
      Détails de la traduction : {{ this.$route.params.translationKey }}
    </v-card-title>

    <LocalSwitcher
      @updateCurrentLocale="updateCurrentLocale($event)"
    ></LocalSwitcher>

    <v-card-text>
      <v-form
        v-model="valid"
        class="multi-col-validation mt-5"
        @submit.prevent="checkForm"
      >
        <p v-if="errors.length">
          <v-alert
            v-for="(error, index) in errors"
            :key="index"
            text
            dense
            type="error"
          >
            {{ error.title }}
          </v-alert>
        </p>

        <Snackbar
          v-if="successMessage"
          :key="generateUniqueId()"
          :snackbar-state="snackbar"
          :success-message="successMessage"
        ></Snackbar>

        <v-row>
          <v-col
            cols="10"
            md="10"
          >
            <v-text-field
              v-model="translationValues[currentLocale]"
              label="Libellé"
              class="mb-5"
              outlined
              dense
              placeholder="Libellé"
              hide-details
            >
              <template v-slot:append>
                <country-flag
                  class="inner-input-flag"
                  :country="currentLocale === 'en' ? 'gb' : currentLocale"
                  size="normal"
                  shadow
                />
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              class="mt-5 mr-2"
              color="primary"
              :disabled="!valid"
              type="submit"
            >
              Valider
            </v-btn>

            <v-btn
              class="mt-5"
              text
              :to="{ name: 'translationList' }"
            >
              Retour
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import config from '../../config'
import LocalSwitcher from '@/components/Common/LocalSwitcher'
import Snackbar from '@/components/Common/Snackbar'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    Snackbar,
    LocalSwitcher,
    Loader
  },
  data () {
    return {
      valid: false,
      errors: [],
      snackbar: false,
      successMessage: null,
      loading: false,
      currentLocale: 'fr',
      translation: null,
      translations: [],
      translationValues: [],
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline
      }
    }
  },
  created () {
    this.getTranslation()
  },
  methods: {
    checkForm () {
      this.errors = []

      if (this.valid) {
        this.putTranslation()
      }
    },
    getTranslation () {
      const translationKey = this.$route.params.translationKey

      this.loading = true

      Vue.prototype.$http
        .get(config.apiUrl + '/api/translations/' + translationKey, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          const computedTranslationsValue = []

          if (response.status === 200) {
            this.translations = response.data
            this.translation = response.data[0]

            for (let i = 0; i < this.translations.length; i++) {
              let currentTranslation = this.translations[i]
              computedTranslationsValue[currentTranslation.locale] = currentTranslation.translation
            }
          }

          this.translationValues = computedTranslationsValue

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    putTranslation () {
      this.valid = false
      this.successMessage = null
      this.loading = true

      const data = []

      for (const [currentLoc, currentVal] of Object.entries(this.translationValues)) {
        data.push({
          domain: this.translation.domain,
          id: this.translation.id,
          key: this.translation.key,
          locale: currentLoc,
          translation: currentVal
        })
      }

      Vue.prototype.$http
        .put(config.apiUrl + '/api/translations/' + this.translation.key, data, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            this.errors = []
            this.snackbar = true
            this.successMessage = 'Traduction mise à jour avec succès.'
            this.valid = true
          }

          this.loading = false
        })
        .catch(e => {
          const response = e.response

          this.successMessage = null

          if (response.status === 400 || response.status === 404) {
            const data = response.data
            this.errors = data.violations
          }

          this.valid = true
          this.loading = false
        })
    },
    updateCurrentLocale (locale) {
      this.currentLocale = locale
      this.successMessage = null
    }
  }
}
</script>
